import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'accountTypeInput',
    'form',
    'accountSelect',
    'directLink',
    'portfolioStrategy',
    'allocation',
    'autoAdjust',
    'solo401kBanner',
    'linkContainer',
  ];

  // Start: Account types

  SOLO_401K = 'solo_401k';

  INDIVIDUAL_CASH_RESERVE = 'individual_cash_reserve';

  // END

  portfolioStrategyTarget: HTMLElement;

  autoAdjustTarget: HTMLElement;

  allocationTarget: HTMLElement;

  accountTypeInputTarget: HTMLSelectElement;

  formTarget: HTMLFormElement;

  directLinkTarget: HTMLInputElement;

  solo401kBannerTarget: HTMLElement;

  linkContainerTarget: HTMLElement;

  connect() {
    const accountType = this.accountTypeInputTarget.value;
    if (accountType) {
      this.updateData(this.accountTypeInputTarget);
    }
  }

  updateLegalAccountType({ target }: { target: HTMLSelectElement }): void {
    this.updateData(target);
  }

  updateData(target: HTMLSelectElement) {
    const { value } = target;
    this.accountTypeInputTarget.value = value;

    this.updateURL(value);
    this.hideOrShowPortfolioData(value);
    this.hideOrShowSolo401kBanner(value);
  }

  updateURL(accountType: string) {
    const url = new URL(this.directLinkTarget.value);
    url.searchParams.set('account_type', accountType);
    this.directLinkTarget.value = url.toString();
  }

  hideOrShowPortfolioData(value: string) {
    const valuesToHideStrategy = [this.INDIVIDUAL_CASH_RESERVE, this.SOLO_401K];

    if (valuesToHideStrategy.includes(value)) {
      this.setHiddenPortfolioData(true);
    } else {
      this.setHiddenPortfolioData(false);
    }
  }

  hideOrShowSolo401kBanner(value: string) {
    if (value === this.SOLO_401K) {
      this.setHiddenSolo401kBanner(false);
    } else {
      this.setHiddenSolo401kBanner(true);
    }
  }

  setHiddenPortfolioData(hidden: boolean) {
    this.portfolioStrategyTarget.hidden = hidden;
    this.allocationTarget.hidden = hidden;
    this.autoAdjustTarget.hidden = hidden;
  }

  setHiddenSolo401kBanner(hidden: boolean) {
    this.solo401kBannerTarget.hidden = hidden;
    this.linkContainerTarget.hidden = !hidden;
  }
}
