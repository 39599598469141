import { scaleLinear } from 'd3-scale';
import { Selection, select } from 'd3-selection';

export interface BulletChartSection {
  start: number;
  end: number;
  status?: string;
}

export interface BulletChartSettings {
  margin?: { left: number; top: number; right: number; bottom: number };
  graphHeight?: number;
  measure: number;
  width?: number;
  height?: number;
  element?: SVGElement;
  sections: BulletChartSection[];
  status?: string;
  minLabel?: string;
  maxLabel?: string;
  optionalMeasure?: number;
  optionalMeasureLabel?: string;
}

export type BulletChartOptions = Omit<BulletChartSettings, 'element'>;

const SEGMENT_SPACING = 4;

export default class BulletChart {
  settings: BulletChartSettings;

  scales: { x: ReturnType<typeof scaleLinear> };

  svg: Selection<SVGGElement, {}, null, undefined>;

  constructor(element: BulletChartSettings['element'], options: BulletChartOptions) {
    const DEFAULTS: Pick<BulletChartSettings, 'margin' | 'graphHeight'> = {
      margin: {
        left: 0,
        top: 0,
        right: 5,
        bottom: 0,
      },
      graphHeight: Math.min(0.3 * element.parentElement.clientWidth, 75),
    };
    this.settings = { ...DEFAULTS, ...options, element };
    this.settings.width = this.settings.element.parentElement.clientWidth - this.settings.margin.right;
    this.settings.height = this.settings.graphHeight - this.settings.margin.top - this.settings.margin.bottom;
  }

  draw() {
    this.setUpScales();
    this.emptyLayout();
    this.createLayout();
    this.drawData();
  }

  setUpScales() {
    this.scales = {
      x: scaleLinear().domain([0, 1]),
    };
  }

  emptyLayout() {
    const svg = this.settings.element;
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }
  }

  createLayout() {
    this.svg = select(this.settings.element)
      .attr('width', this.settings.element.parentElement.clientWidth)
      .attr('height', this.settings.height + this.settings.margin.top + this.settings.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.settings.margin.left},${this.settings.margin.top})`);

    this.svg
      .append('rect')
      .attr('y', this.center - this.barHeight / 2)
      .attr('width', this.settings.width)
      .attr('height', this.barHeight)
      .attr('class', 'BulletChart-chartBackground');
  }

  drawArea() {
    this.svg
      .append('rect')
      .attr('y', this.center - this.barHeight / 2)
      .attr('width', this.settings.width * this.settings.measure)
      .attr('height', this.barHeight)
      .attr('class', `BulletChart-chart BulletChart-chart--${this.settings.status}`);
  }

  drawSections() {
    const { width, sections } = this.settings;
    const barY = this.center - this.barHeight / 2;
    const endRadius = 2;
    const leftSection = sections[0];
    const rightSection = sections[sections.length - 1];

    const leftSectionWidth = width * (leftSection.end - leftSection.start) - SEGMENT_SPACING;
    this.svg
      .append('path')
      .attr('class', `BulletChart-chart BulletChart-chart--${leftSection.status}`)
      .attr(
        'd',
        `
          M ${leftSectionWidth} ${barY}
          v ${this.barHeight}
          h -${leftSectionWidth - endRadius}
          q -${endRadius} 0 -${endRadius} -${endRadius}
          v -${this.barHeight - endRadius * 2}
          q 0 -${endRadius} ${endRadius} -${endRadius}
          h ${leftSectionWidth - endRadius}
        `
      );

    sections.slice(1, -1).forEach(section => {
      const sectionWidth = width * (section.end - section.start) - SEGMENT_SPACING;
      this.svg
        .append('path')
        .attr('class', `BulletChart-chart BulletChart-chart--${section.status}`)
        .attr(
          'd',
          `
            M ${width * section.start} ${barY}
            h ${sectionWidth}
            v ${this.barHeight}
            h -${sectionWidth}
            v -${this.barHeight}
          `
        );
    });

    const rightSectionWidth = width - width * rightSection.start;
    this.svg
      .append('path')
      .attr('class', `BulletChart-chart BulletChart-chart--${rightSection.status}`)
      .attr(
        'd',
        `
          M ${width - rightSectionWidth} ${barY}
          h ${rightSectionWidth - endRadius}
          q ${endRadius} 0 ${endRadius} ${endRadius}
          v ${this.barHeight - endRadius * 2}
          q 0 ${endRadius} -${endRadius} ${endRadius}
          h -${rightSectionWidth - endRadius}
          v -${this.barHeight}
        `
      );
  }

  drawMeasure() {
    const measureHeight = this.barHeight * 3;
    const measureY = this.center - measureHeight / 2;
    this.svg
      .append('rect')
      .attr('height', measureHeight)
      .attr('width', 8)
      .attr('y', measureY)
      .attr('x', this.settings.width * this.settings.measure - SEGMENT_SPACING / 2)
      .attr('class', 'BulletChart-markerBackground');

    this.svg
      .append('rect')
      .attr('height', measureHeight)
      .attr('width', SEGMENT_SPACING)
      .attr('y', measureY)
      .attr('ry', 2)
      .attr('x', this.settings.width * this.settings.measure)
      .attr('rx', 2)
      .attr('class', 'BulletChart-marker');
  }

  drawOptionalMeasure() {
    const measureHeight = this.barHeight * 3;
    const measureY = this.center - measureHeight / 2;
    this.svg
      .append('rect')
      .attr('height', measureHeight)
      .attr('width', 8)
      .attr('y', measureY)
      .attr('x', this.settings.width * this.settings.optionalMeasure - SEGMENT_SPACING / 2)
      .attr('class', 'BulletChart-markerBackground');

    this.svg
      .append('rect')
      .attr('height', measureHeight)
      .attr('width', 2)
      .attr('y', measureY)
      .attr('ry', 2)
      .attr('x', this.settings.width * this.settings.optionalMeasure)
      .attr('rx', 2)
      .attr('class', 'BulletChart-optionalMarker');

    this.svg
      .append('text')
      .attr('class', 'BulletChart-optionalMarkerLabel')
      .attr('alignment-baseline', 'hanging')
      .attr('x', this.settings.width * this.settings.optionalMeasure)
      .attr('y', this.center + this.barHeight / 2 + this.labelHeight)
      .text(this.settings.optionalMeasureLabel);
  }

  drawLabels() {
    this.svg
      .append('text')
      .attr('class', 'BulletChart-label')
      .attr('alignment-baseline', 'hanging')
      .attr('x', 0)
      .attr('y', this.center + this.barHeight / 2 + this.labelHeight)
      .text(this.settings.minLabel);

    this.svg
      .append('text')
      .attr('class', 'BulletChart-label')
      .attr('alignment-baseline', 'hanging')
      .attr('text-anchor', 'end')
      .attr('x', this.settings.width)
      .attr('y', this.center + this.barHeight / 2 + this.labelHeight)
      .text(this.settings.maxLabel);
  }

  drawData() {
    this.drawSections();
    this.drawMeasure();
    if (this.settings.optionalMeasure) {
      this.drawOptionalMeasure();
    }
    if (this.settings.minLabel || this.settings.maxLabel) {
      this.drawLabels();
    }
  }

  get center() {
    return this.settings.height / 2;
  }

  get barHeight() {
    return 8;
  }

  get labelHeight() {
    return 10;
  }
}
