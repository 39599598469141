import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cryptoAgreementAccepted', 'createAccountButton'];

  cryptoAgreementAcceptedTarget: HTMLInputElement;

  createAccountButtonTarget: HTMLButtonElement;

  connect(): void {
    this.determineButtonState();
  }

  toggleCryptoAgreementAccepted(): void {
    this.determineButtonState();
  }

  determineButtonState(): void {
    if (this.cryptoAgreementAcceptedTarget.checked) {
      this.createAccountButtonTarget.disabled = false;
    } else {
      this.createAccountButtonTarget.disabled = true;
    }
  }
}
