import { getCsrfToken } from './csrf';

export const getCommonFetchConfig = (method = 'post'): RequestInit => ({
  headers: {
    'X-CSRF-Token': getCsrfToken(),
    'X-REQUESTED-WITH': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
  credentials: 'same-origin',
  method,
});
