import { parseInteger } from './helpers';

export default class SelfDirectedPortfolio {
  components: string[];

  constructor(components: string[]) {
    this.components = components;
  }

  isValid() {
    return this.hasValidComponents() && this.hasValidTotalWeight();
  }

  hasValidTotalWeight() {
    return this.total === 100;
  }

  hasValidComponents() {
    return this.components.every(component => {
      return (
        parseInteger(component) === Number(component) &&
        parseInteger(component) >= 0 &&
        parseInteger(component) <= 100 &&
        parseFloat(component) % 1 === 0
      );
    });
  }

  get total() {
    return this.components
      .map(component => {
        return parseInteger(component) || 0;
      })
      .reduce((sum, value) => {
        return sum + value;
      });
  }
}
