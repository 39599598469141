import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyApp = () => Promise<{ default: React.ComponentType<React.PropsWithChildren<any>> }>;

interface AppRegistry {
  [key: string]: LazyApp;
}

const appRegistry: AppRegistry = {
  Summary: () => import('./apps/SummaryApp'),
  SecurityGroupTable: () => import('./apps/SecurityGroupTableApp'),
  MfaSudoChallengeFlow: () => import('./apps/MfaSudoChallengeFlowApp'),
  MfaSmsSetupFlow: () => import('./apps/MfaSmsSetupFlowApp'),
  TieredBillingConfigurationForm: () => import('./apps/TieredBillingConfigurationFormApp'),
  FourOhOneKLead: () => import('./apps/FourOhOneKLeadApp'),
  FourOhOneKDashboard: () => import('./apps/FourOhOneKDashboardApp'),
  IraConversionFlow: () => import('./apps/IraConversionFlowApp'),
  CoPilot: () => import('./apps/CoPilotApp'),
  CustomPortfolios: () => import('./apps/CustomPortfoliosApp'),
  CustomPortfoliosV2: () => import('./apps/CustomPortfoliosV2App'),
  Clients: () => import('./apps/ClientsApp'),
  AddAccounts: () => import('./apps/AddAccountsApp'),
  AdvisorMfaSetupTotp: () => import('./apps/AdvisorMfaSetupTotpApp'),
  EtfPortfolios: () => import('./apps/EtfPortfoliosApp'),
  GoldmanSachsTaxSmartBondAdviceApp: () => import('./apps/GoldmanSachsTaxSmartBondAdviceApp'),
  FirmIntegrations: () => import('./apps/FirmIntegrationsApp'),
  GoalCreationFlow: () => import('./apps/GoalCreationFlowApp'),
  Solo401kConversion: () => import('./apps/Solo401kConversionApp'),
  Household: () => import('./apps/HouseholdApp'),
  Start401kConversionBannerApp: () => import('./apps/Start401kConversionBannerApp'),
  PostApproval401kBannerApp: () => import('./apps/PostApproval401kBannerApp'),
  CashBillingPrompt: () => import('./apps/CashBillingPrompt'),
  PortfoliosApp: () => import('./apps/PortfoliosApp'),
  BillingPlanIndexPage: () => import('./apps/BillingPlanIndexPageApp'),
  ClientTours: () => import('./apps/ClientToursApp'),
};

const retry = (fn: LazyApp, retriesLeft = 5, interval = 1000) => {
  return new Promise<{ default: React.ComponentType }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: Error) => {
        if (retriesLeft === 0) {
          reject(error);
          return;
        }

        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const getApp = (name: string) => React.lazy(() => retry(appRegistry[name]));

export default {
  getApp,
};
