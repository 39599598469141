import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'formElement',
    'primaryUserSelectElement',
    'accountTypeSelectElement',
    'secondaryUserContainer',
    'secondaryUserSelectElement',
    'submitButton',
  ];
  /* eslint-disable */
  formElementTarget: HTMLFormElement;

  primaryUserSelectElementTarget: HTMLInputElement;

  accountTypeSelectElementTarget: HTMLInputElement;

  secondaryUserContainerTarget: HTMLElement;

  secondaryUserSelectElementTarget: HTMLInputElement;

  submitButtonElement: HTMLButtonElement;

  step: string;

  connect(): void {
    this.accountTypeSelectElementTarget.addEventListener('change', this.accountTypeSelected.bind(this));
    this.primaryUserSelectElementTarget.addEventListener('change', this.primaryUserChange.bind(this));

    this.accountTypeSelected({ target: this.accountTypeSelectElementTarget });
    this.userSelectionChange(this.secondaryUserSelectElementTarget, this.primaryUserSelectElementTarget.value);
    this.conformSecondaryUserSelection(this.primaryUserSelectElementTarget);

    this.step = 'accountType';
  }

  jointAccountType(): void {
    this.secondaryUserContainerTarget.classList.remove('hidden');
  }

  individualAccountType(): void {
    this.secondaryUserContainerTarget.classList.add('hidden');
  }

  primaryUserChange({ target }): void {
    this.userSelectionChange(this.secondaryUserSelectElementTarget, target.value);
    this.conformSecondaryUserSelection(target.value);
  }

  conformSecondaryUserSelection(value): void {
    if (value === this.secondaryUserSelectElementTarget.value) {
      const option = this.secondaryUserSelectElementTarget.querySelector(
        `option:not([value="${value}"])`
      ) as HTMLInputElement;
      this.secondaryUserSelectElementTarget.value = String(option.value);
    }
  }

  userSelectionChange(elementToChange: HTMLInputElement, value: string): void {
    elementToChange.querySelector(`option[value="${value}"]`).classList.add('hidden');
    const rest = Array.from(elementToChange.querySelectorAll(`option:not([value="${value}"])`)) as HTMLElement[];

    rest.forEach((element: HTMLElement) => {
      element.classList.remove('hidden');
    });

    this.step = 'secondaryUser';
  }

  accountTypeSelected({ target }): void {
    switch (target.value) {
      case 'joint':
        this.jointAccountType();
        this.conformSecondaryUserSelection(this.primaryUserSelectElementTarget.value);
        break;
      case 'individual':
      case 'trust':
        this.individualAccountType();
        break;
    }

    this.step = 'secondaryUser';
  }

  changeStep(e: Event | null = null): void {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  submitForm(): void {
    this.formElementTarget.submit();
  }
}
