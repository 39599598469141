/// <reference types="vite/client" />
import { startStimulusApplication } from '../../index';
import * as scLegacyInitializers from '@betterment/style-closet-legacy';
import jQuery from 'jquery';
import Rails from '@rails/ujs';
import 'jquery-pjax';
import 'jquery-ui';
import 'lodash';
import 'qtip2';
import '@selectize/selectize';
import '@betterment/style-closet-elements';

import '../vendor/highlight.pack.js';
import '../vendor/jquery-readyselector.js';
import '../vendor/jquery.browser.js';
import '../vendor/jQuerySpinnerPlugin.js';
import '../vendor/typed-jquery.js';
import '../scripts/form_behaviors/actionMenuBehavior.js';
import '../scripts/form_behaviors/compositeModifier.js';
import '../scripts/form_behaviors/compositeSecondaryAdvisor.js';
import '../scripts/form_behaviors/fileInputBehavior.js';
import '../scripts/form_behaviors/formRefresh.js';
import '../scripts/form_behaviors/injectForm.js';
import '../scripts/form_behaviors/inputBehavior.js';
import '../scripts/form_behaviors/radioBehavior.js';
import '../scripts/form_behaviors/removeElementBehavior.js';
import '../scripts/analytics.js';
import '../scripts/modal_auto_open.js';
import '../scripts/pjax.js';
import '../scripts/spinner.js';

(window as any).$ = jQuery;
(window as any).jQuery = jQuery;

for (const initializer in scLegacyInitializers) {
  scLegacyInitializers[initializer]();
}

if (!window._rails_loaded) Rails.start();

startStimulusApplication();
