import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener('load', () => {
      const PREVENT_MODAL_CLOSE_ERROR = 'prevent-modal-close';
      const $modal = $('[data-sc-modal-content="mfa-call-to-action"]');
      $modal.on('sc-Modal.close', () => {
        if ($modal.data('prevent-close') === true) {
          throw PREVENT_MODAL_CLOSE_ERROR;
        }
      });
      window.addEventListener('error', (event): boolean => {
        if (event.error === PREVENT_MODAL_CLOSE_ERROR) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();
          return true;
        }
        return false;
      });
      $modal.find('.sc-Modal-close').css('display', 'none');
      const $enableForm = $('[data-mfa-call-to-action-enable-form]');
      $enableForm.on('ajax:success', () => {
        window.location.href = $enableForm.data('redirect') as string;
      });
      $enableForm.on('ajax:error', () => {
        throw Error('System error');
      });
      const $skipForm = $('[data-mfa-call-to-action-skip-form]');
      $skipForm.on('ajax:success', () => {
        $modal.data('prevent-close', false);
        $modal.find('.sc-Modal-close').click();
      });
      $skipForm.on('ajax:error', () => {
        throw Error('System error');
      });
    });
  }
}
