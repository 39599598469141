import { Controller } from 'stimulus';

import BulletChart, { BulletChartOptions, BulletChartSection } from '../graphs/bullet-chart';

class ControllerBase extends Controller {
  svgTarget: SVGElement;
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['svg'];

  resizeListener: () => void;

  connect() {
    this.draw();
    this.connectResizeListener();
  }

  disconnect() {
    this.disconnectResizeListener();
  }

  draw() {
    const chart = new BulletChart(this.svgTarget, this.graphOptions);
    chart.draw();
  }

  connectResizeListener() {
    this.resizeListener = this.draw.bind(this) as () => void;
    window.addEventListener('resize', this.resizeListener);
  }

  disconnectResizeListener() {
    window.removeEventListener('resize', this.resizeListener);
  }

  get graphOptions(): BulletChartOptions {
    return {
      minLabel: this.data.get('min-label'),
      maxLabel: this.data.get('max-label'),
      measure: parseFloat(this.data.get('measure')),
      sections: JSON.parse(this.data.get('sections')) as BulletChartSection[],
      status: this.data.get('status'),
      optionalMeasure: parseFloat(this.data.get('optional-measure')),
      optionalMeasureLabel: this.data.get('optional-measure-label'),
    };
  }
}
