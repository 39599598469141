import React from 'react';

export interface FeatureFlagContextData {
  getFeatureFlag: (string) => FeatureFlag;
}

export interface FeatureFlag {
  enabled: boolean;
  loading: boolean;
}

interface FeatureFlagMap {
  [splitNanme: string]: FeatureFlag;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextData>(null);

export const FeatureFlagProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const featureFlagMap: FeatureFlagMap = {};

  const getFeatureFlag = (splitName: string) => {
    return featureFlagMap[splitName];
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <FeatureFlagContext.Provider value={{ getFeatureFlag }}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlagState = () => {
  const context = React.useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeatureFlagState must be used under FeatureFlagProvider');
  }
  return context;
};
