import { Controller } from 'stimulus';

import ReturnGraphController from './returns-graph-controller';

export default class extends Controller {
  connect() {
    this.connectInputListener();
  }

  disconnect() {
    this.disconnectInputListener();
  }

  connectInputListener() {
    this.tabNavCardTarget.addEventListener(
      'change',
      this.redrawGraphs.bind(this) as EventListenerOrEventListenerObject
    );
  }

  disconnectInputListener() {
    this.tabNavCardTarget.removeEventListener(
      'change',
      this.redrawGraphs.bind(this) // eslint-disable-line @typescript-eslint/no-unsafe-argument
    );
  }

  redrawGraphs() {
    // eslint-disable-line @typescript-eslint/no-unsafe-argument
    this.returnsGraphController.draw();
  }

  setValid(valid) {
    if (valid) {
      this.element.classList.remove('invalid');
    } else {
      this.element.classList.add('invalid');
    }
  }

  get tabNavCardTarget() {
    return this.element.querySelector('.sc-TabNavCard');
  }

  get tabNavController() {
    return this.application.getControllerForElementAndIdentifier(this.tabNavCardTarget, 'tab-nav-card');
  }

  get returnsGraphTarget() {
    return this.element.querySelector(
      '.SelfDirectedAllocationSelection-returnsProjectionComponent [data-controller="returns-graph"]'
    );
  }

  get returnsGraphController() {
    return this.application.getControllerForElementAndIdentifier(
      this.returnsGraphTarget,
      'returns-graph'
    ) as ReturnGraphController;
  }
}
