import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (this.noActiveItemSet) {
      this.activateItem(this.firstItemKey);
    }
  }

  get firstItemKey() {
    return this.firstItemTab.getAttribute('data-key');
  }

  get firstItemTab() {
    return this.element.querySelector('.sc-TabNavCard-itemTab');
  }

  get activeItemTab() {
    return this.element.querySelector('.sc-TabNavCard-itemTab.is-active');
  }

  get activeItemContent() {
    return this.element.querySelector('.sc-TabNavCard-itemContent.is-active');
  }

  get noActiveItemSet() {
    return !this.activeItemTab;
  }

  clickItemTab(event: MouseEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const newlyActiveItemKey = target.getAttribute('data-key');

    this.deactivateActiveItem();
    this.activateItem(newlyActiveItemKey);
    this.triggerChangeEvent();
  }

  triggerChangeEvent() {
    const event = new Event('change');
    this.element.dispatchEvent(event);
  }

  deactivateActiveItem() {
    this.activeItemTab.classList.remove('is-active');
    this.activeItemContent.classList.remove('is-active');
  }

  activateItem(key: string) {
    const newlyActiveItemTab = this.element.querySelector(`.sc-TabNavCard-itemTab[data-key="${key}"]`);
    const newlyActiveItemContent = this.element.querySelector(`.sc-TabNavCard-itemContent[data-key="${key}"]`);
    newlyActiveItemTab.classList.add('is-active');
    newlyActiveItemContent.classList.add('is-active');
  }
}
