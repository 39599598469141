import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    hasTcpGoal: String,
  };

  static targets = ['accountTypeInput', 'tcpFlash'];

  accountTypeInputTarget: HTMLSelectElement;

  tcpFlashTarget: HTMLDivElement;

  connect() {
    this.accountTypeChanged();
  }

  accountTypeChanged() {
    const { value } = this.accountTypeInputTarget;

    this.hideOrShowTcpFlash(value);
  }

  hideOrShowTcpFlash(value: string) {
    if (value === 'solo_401k') {
      this.setTcpFlashHidden(false);
    } else {
      this.setTcpFlashHidden(true);
    }
  }

  setTcpFlashHidden(hidden: boolean) {
    this.tcpFlashTarget.hidden = hidden;
  }
}
