import $ from 'jquery';
import { Controller } from 'stimulus';

const CONFIRMATION_MODAL_SELECTOR = "[data-sc-modal-content='mutual-fund-warning']";

export default class extends Controller {
  static targets = [
    'addCashLink',
    'cashSharesInput',
    'cashAllSharesCheckbox',
    'sharesInput',
    'submitButton',
    'assetsCollectionForm',
    'cashAssetRow',
    'confirmationModal',
    'confirmationRequiredAsset',
    'assetErrorDiv',
    'agreementCheckBox',
    'confirmationModalSubmitButton',
    'modalNoSecuritiesEnteredMessage',
    'modalNotAllCashMessage',
  ];

  modalNoSecuritiesEnteredMessageTarget: HTMLElement;

  addCashLinkTarget: HTMLAnchorElement;

  confirmationModalSubmitButtonTarget: HTMLButtonElement;

  cashSharesInputTarget: HTMLInputElement;

  cashAllSharesCheckboxTarget: HTMLInputElement;

  sharesInputTarget: HTMLInputElement;

  submitButtonTarget: HTMLButtonElement;

  agreementCheckBoxTarget: HTMLElement;

  assetsCollectionFormTarget: HTMLFormElement;

  cashAssetRowTarget: HTMLElement;

  confirmationModalTarget: HTMLElement;

  assetErrorDivTarget: HTMLElement;

  hasConfirmationRequiredAssetTarget: boolean;

  hasCashSharesInputTarget: boolean;

  hasSharesInputTarget: boolean;

  confirmed: boolean;

  submitButtonNotAllCashTarget: HTMLButtonElement;

  modalNotAllCashMessageTarget: HTMLElement;

  showAddCashLink() {
    this.addCashLinkTarget.classList.remove('u-displayNone');
    this.addCashLinkTarget.classList.add('u-displayBlock');
  }

  submit(event: Event) {
    if (this.hasConfirmationRequiredAssetTarget && !this.confirmationModalTarget.classList.contains('is-open')) {
      event.stopPropagation();
      event.preventDefault();
      this.confirmationModalTriggerTarget.click();
    }
  }

  toggleTermsAcceptance() {
    this.confirmed = !this.confirmed;
    this.confirmationModalSubmitButtonTarget.disabled = !this.confirmed;
  }

  saveAssets() {
    const $assetsCollectionFormTarget = $(this.assetsCollectionFormTarget);
    const url = $assetsCollectionFormTarget.data('remote-action') as string;
    const data = $assetsCollectionFormTarget.serialize();

    void $.ajax({
      type: 'PUT',
      url,
      data,
    }).catch();
  }

  removeCashAsset() {
    this.cashAssetRowTarget.remove();
    this.showAddCashLink();
    this.validate();
  }

  updateShares() {
    if (this.cashAllSharesCheckboxTarget.checked) {
      this.clearSharesInput();
      this.disableSharesInput();
    } else {
      this.enableSharesInput();
    }
  }

  enableSharesInput() {
    this.cashAllSharesCheckboxTarget.checked = false;
    this.cashSharesInputTarget.classList.remove('is-blocked');
    this.cashSharesInputTarget.focus();
  }

  clearSharesInput() {
    this.cashSharesInputTarget.value = '';
  }

  disableSharesInput() {
    this.cashSharesInputTarget.classList.add('is-blocked');
  }

  anyAssets() {
    return !!(this.hasCashSharesInputTarget || this.hasSharesInputTarget);
  }

  toggleErrorMessage() {
    if (this.anyAssets()) {
      this.assetErrorDivTarget.classList.add('u-displayNone');
    } else {
      this.assetErrorDivTarget.classList.remove('u-displayNone');
    }
  }

  get confirmationModalTriggerTarget() {
    return this.submitButtonTarget.parentElement;
  }

  enableConfirmationModalTrigger() {
    this.confirmationModalTriggerTarget.dataset.scModalTarget = CONFIRMATION_MODAL_SELECTOR;
  }

  disableConfirmationModalTrigger() {
    if (this.confirmationModalTriggerTarget.dataset.scModalTarget) {
      delete this.confirmationModalTriggerTarget.dataset.scModalTarget;
    }
  }

  toggleConfirmationModalTrigger() {
    if (this.hasConfirmationRequiredAssetTarget || !this.anyAssets()) {
      this.enableConfirmationModalTrigger();
    } else {
      this.disableConfirmationModalTrigger();
    }
  }

  validate() {
    this.toggleConfirmationModalTrigger();
  }

  modalProperMessage() {
    if (this.anyAssets()) {
      this.modalNoSecuritiesEnteredMessageTarget.hidden = true;
      this.modalNotAllCashMessageTarget.hidden = false;
    } else {
      this.modalNotAllCashMessageTarget.hidden = true;
      this.modalNoSecuritiesEnteredMessageTarget.hidden = false;
    }
  }

  connect() {
    this.confirmed = false;
    this.validate();
    this.modalProperMessage();
  }
}
