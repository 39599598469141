import { Controller } from 'stimulus';

class ControllerBase extends Controller {
  sectionTargets: HTMLInputElement[];
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['section', 'input', 'output'];

  connect() {
    this.sectionTargets.forEach(section => this.updateSectionLabel(section));
  }

  valueChanged() {
    this.sectionTargets.forEach(section => this.updateSectionLabel(section));
    this.triggerInputEvent();
  }

  updateSectionLabel(section: HTMLInputElement) {
    const label = section.querySelector(
      `[data-target="rollup-table.output"][data-section="${section.dataset.section}"]`
    );
    label.innerHTML = this.totalForSection(section).toString();
  }

  triggerInputEvent() {
    const event = new Event('input');
    this.element.dispatchEvent(event);
  }

  totalForSection(section: HTMLInputElement) {
    const inputs = section.querySelectorAll<HTMLInputElement>('input[data-target="rollup-table.input"]');

    const total = Array.from(inputs)
      .map(input => {
        return Number(input.value) || 0;
      })
      .reduce((sum, value) => {
        return sum + value;
      });

    return total;
  }

  setValid(valid: boolean) {
    if (valid) {
      this.element.classList.remove('invalid');
    } else {
      this.element.classList.add('invalid');
    }
  }

  get inputs() {
    return this.element.querySelectorAll<HTMLInputElement>('input[data-target="rollup-table.input"]');
  }

  get values() {
    return Array.from(this.inputs).map(input => input.value);
  }
}
