import React from 'react';

import { DataFetchProvider } from '@betterment/js-runtime';
import { GlobalErrorFallback, StyleClosetThemeProvider, ThemeName } from '@betterment/style-closet-react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Controller } from 'stimulus';

import appShell from '../AppShell';
import { FullPageLoader } from '../components/FullPageLoader';
import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';

export default class extends Controller {
  connect() {
    const App = Sentry.withProfiler(this.app, { name: this.name });

    if (App) {
      const root = createRoot(this.element);
      root.render(
        <Sentry.ErrorBoundary
          fallback={GlobalErrorFallback as Sentry.FallbackRender}
          beforeCapture={scope => {
            scope.setTransactionName(this.name);
          }}
        >
          <React.Suspense fallback={this.suspenseFallback}>
            <StyleClosetThemeProvider theme={this.theme}>
              <DataFetchProvider>
                <FeatureFlagProvider>
                  <App {...this.initialProps} />
                </FeatureFlagProvider>
              </DataFetchProvider>
            </StyleClosetThemeProvider>
          </React.Suspense>
        </Sentry.ErrorBoundary>
      );
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Could not resolve app with name: '${this.name}'`);
    }
  }

  get app() {
    return appShell.getApp(this.name);
  }

  get name() {
    return this.data.get('name');
  }

  get suspenseFallback() {
    let fallback = <FullPageLoader />;
    if (this.initialProps?.suspenseFallback === false) {
      fallback = null;
    }
    return fallback;
  }

  get theme() {
    return (document.querySelector<HTMLMetaElement>('meta[name="theme"]')?.content || 'default') as ThemeName;
  }

  get initialProps() {
    return JSON.parse(this.data.get('initial-props') || '{}') as Record<string, unknown>;
  }
}
