import { Controller } from 'stimulus';

import { getCsrfToken } from '../csrf';

export default class extends Controller {
  static values = {
    redirect: String,
    dismissUrl: String,
  };

  static targets = ['welcome', 'financialAccounts', 'clientAccounts', 'completion', 'modal'];

  welcomeTarget: HTMLDivElement;

  financialAccountsTarget: HTMLDivElement;

  clientAccountsTarget: HTMLDivElement;

  completionTarget: HTMLDivElement;

  modalTarget: HTMLDivElement;

  closeIcon: HTMLInputElement | null;

  connect(): void {
    this.closeIcon = this.modalTarget.querySelector('.sc-Modal-close');
    if (this.closeIcon) {
      this.closeIcon.addEventListener('click', () => {
        this.close();
      });
      this.modalTarget.classList.add('is-open');
    }
  }

  financialAccountsStep() {
    this.toggleStep(this.welcomeTarget);
    this.toggleStep(this.financialAccountsTarget);
  }

  clientAccountsStep() {
    this.toggleStep(this.financialAccountsTarget);
    this.toggleStep(this.clientAccountsTarget);
  }

  completionStep() {
    this.toggleStep(this.clientAccountsTarget);
    this.toggleStep(this.completionTarget);
  }

  toggleStep(el: HTMLElement) {
    el.classList.toggle('u-displayNone');
  }

  saveModelDismiss(): void {
    void fetch(this.data.get('dismissUrl'), {
      headers: {
        'X-CSRF-Token': getCsrfToken(),
        'X-REQUESTED-WITH': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      method: 'post',
    });
  }

  closeAndRedirect() {
    this.closeIcon.click();
    window.location.href = this.data.get('redirectValue');
  }

  close(): void {
    this.saveModelDismiss();
  }
}
