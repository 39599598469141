import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['agreementCheckBox', 'submitButton'];

  agreementCheckBoxTarget: HTMLInputElement;

  submitButtonTarget: HTMLInputElement;

  toggleTermsAcceptance() {
    this.submitButtonTarget.disabled = !this.agreementCheckBoxTarget.checked;
  }
}
