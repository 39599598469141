import _debounce from 'lodash/debounce';
import { Controller } from 'stimulus';

const PULSE_BUTTON_OFFSET = 5;

export default class extends Controller {
  static targets = ['step'];

  stepTargets: HTMLElement[];

  resizeListener: () => void;

  connect() {
    this.resizeListener = _debounce<() => void>(this.resizeSteps.bind(this) as () => void, 50);
    window.addEventListener('resize', this.resizeListener);
    this.resizeSteps();
  }

  resizeSteps() {
    this.stepTargets.forEach(step => this.positionStep(step));
  }

  positionStep(step: HTMLElement) {
    const offset = step.classList.contains('right') ? -PULSE_BUTTON_OFFSET : PULSE_BUTTON_OFFSET;

    const anchorElement = document.querySelector(step.getAttribute('data-tour-selector'));
    const anchorCoords = anchorElement.getBoundingClientRect();

    step.style.top = `${anchorCoords.top - offset}px`;

    const navElement: HTMLElement = document.querySelector('.ApplicationLayout-nav');
    if (navElement.contains(anchorElement)) {
      step.style.left = `${anchorCoords.left + (step.classList.contains('right') ? anchorCoords.width : 0) - offset}px`;
    } else {
      step.style.left = `${
        anchorCoords.left -
        navElement.offsetWidth +
        (step.classList.contains('right') ? anchorCoords.width : 0) -
        offset
      }px`;
    }
  }

  dismiss() {
    this.stepTargets.forEach(step => {
      step.hidden = true;
    });
  }
}
