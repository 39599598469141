import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sharesInput', 'allSharesCheckbox', 'assetRow'];

  sharesInputTarget: HTMLInputElement;

  allSharesCheckboxTarget: HTMLInputElement;

  assetRowTarget: HTMLElement;

  removeAsset() {
    this.assetRowTarget.remove();
    window.dispatchEvent(new CustomEvent('asset-row:removed'));
  }

  updateShares() {
    if (this.allSharesCheckboxTarget.checked) {
      this.clearSharesInput();
      this.disableSharesInput();
    } else {
      this.enableSharesInput();
    }
  }

  enableSharesInput() {
    this.allSharesCheckboxTarget.checked = false;
    this.sharesInputTarget.classList.remove('is-blocked');
    this.sharesInputTarget.focus();
  }

  clearSharesInput() {
    this.sharesInputTarget.value = '';
  }

  disableSharesInput() {
    this.sharesInputTarget.classList.add('is-blocked');
  }
}
