import AllocationSelectionController from './self-directed-allocation-selection-controller';

export default class extends AllocationSelectionController {
  static targets = ['name', 'taxTreatment'];

  nameTarget: HTMLInputElement;

  taxTreatmentTarget: HTMLInputElement;

  toggleSubmitButton() {
    if (this.portfolio.isValid() && this.nameTarget.value !== '') {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  resetTax() {
    this.taxTreatmentTarget.value = '';
    const event = new Event('change', { bubbles: true });
    this.refreshableElement.dispatchEvent(event);
  }
}
