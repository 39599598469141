import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submitButton', 'confirmCheckbox'];

  confirmCheckboxTarget: HTMLInputElement;

  submitButtonTarget: HTMLInputElement;

  change() {
    this.submitButtonTarget.disabled = !this.confirmCheckboxTarget.checked;
  }
}
