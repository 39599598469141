import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['welcome', 'portfolios', 'creation', 'completion'];

  welcomeTarget: HTMLDivElement;

  portfoliosTarget: HTMLDivElement;

  creationTarget: HTMLDivElement;

  completionTarget: HTMLDivElement;

  portfoliosStep() {
    this.toggleStep(this.welcomeTarget);
    this.toggleStep(this.portfoliosTarget);
  }

  creationStep() {
    this.toggleStep(this.portfoliosTarget);
    this.toggleStep(this.creationTarget);
  }

  completionStep() {
    this.toggleStep(this.creationTarget);
    this.toggleStep(this.completionTarget);
  }

  toggleStep(element: HTMLDivElement) {
    element.classList.toggle('u-displayNone');
  }
}
