import { bisector, extent } from 'd3-array';
import { Axis, axisBottom, axisRight } from 'd3-axis';
import { format } from 'd3-format';
import { ScaleLinear, ScaleTime, scaleLinear, scaleTime } from 'd3-scale';
import { Selection, mouse, select, selectAll } from 'd3-selection';
import { Area, CurveFactory, Line, area, curveBasis, line } from 'd3-shape';
import { timeFormat } from 'd3-time-format'; // eslint-disable-line import/no-extraneous-dependencies

import { parseInteger } from '../models/helpers';

export interface DataPoint {
  [key: string]: Date | number;
  date: Date;
  expected: number;
  ninetyPercentile: number;
  tenPercentile: number;
}

export interface ReturnsGraphSettings {
  margin?: {
    top: number;
    right: number;
    left: number;
    bottom: number;
  };
  width?: number;
  height?: number;
  graphHeight?: number;
  tooltipClass?: string;
  displayYAxis?: boolean;
  interpolation?: CurveFactory;
  element: SVGElement;
  minValue: number;
  maxValue: number;
  valuesByDate: Array<DataPoint>;
  termYears: number;
}

export type ReturnsGraphOptions = Omit<ReturnsGraphSettings, 'element'>;

interface ReturnsGraphGenerators {
  xAxis: Axis<Date>;
  yAxis: Axis<number>;
  line: {
    [key: string]: Line<DataPoint>;
    expected: Line<DataPoint>;
    zero: Line<DataPoint>;
  };
  area: {
    [key: string]: Area<DataPoint>;
    midRange: Area<DataPoint>;
  };
}

interface Scales {
  x: ScaleTime<number, number>;
  y: ScaleLinear<number, number>;
}

export default class ReturnsGraph {
  settings: ReturnsGraphSettings;

  svg: Selection<SVGGElement, DataPoint, HTMLElement, unknown>;

  scales: Scales;

  generators: ReturnsGraphGenerators;

  constructor(element: ReturnsGraphSettings['element'], options: ReturnsGraphOptions) {
    const DEFAULTS: Pick<
      ReturnsGraphSettings,
      'margin' | 'graphHeight' | 'tooltipClass' | 'displayYAxis' | 'interpolation'
    > = {
      margin: {
        top: 20,
        right: 60,
        left: 0,
        bottom: 50,
      },
      graphHeight: Math.min(0.75 * element.parentElement.clientWidth, 375),
      tooltipClass: '.ProjectionGraph-tooltip',
      displayYAxis: false,
      interpolation: curveBasis,
    };

    this.settings = { ...DEFAULTS, ...options, element };
    this.settings.width = this.settings.element.parentElement.clientWidth - this.settings.margin.right;
    this.settings.height = this.settings.graphHeight - this.settings.margin.top - this.settings.margin.bottom;
  }

  draw() {
    this.emptyLayout();
    this.setUpScales();
    this.setUpGenerators();
    this.createLayout();
    this.drawData();
    this.drawHoverBar();
    this.initHover();
  }

  emptyLayout() {
    const svg = this.settings.element;
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }
  }

  setUpScales() {
    const yMin = this.settings.minValue;
    const yMax = this.settings.maxValue;

    this.scales = {
      x: scaleTime()
        .domain(
          extent(this.settings.valuesByDate, d => {
            return d.date;
          })
        )
        .range([0, this.settings.width]),
      y: scaleLinear().domain([yMin, yMax]).range([this.settings.height, 0]).nice(),
    };
  }

  setUpGenerators() {
    const { scales } = this;
    const { interpolation } = this.settings;
    const axisDateFormat = timeFormat(this.settings.termYears > 10 ? '%Y' : '%-m/%Y');
    const xTicks = this.settings.width < 500 ? 3 : 6;
    const yTicks = this.settings.width < 500 ? 4 : 8;

    this.generators = {
      xAxis: axisBottom<Date>(scales.x).tickSize(10).ticks(xTicks).tickPadding(8).tickFormat(axisDateFormat),
      yAxis: axisRight<number>(scales.y)
        .tickSize(10)
        .ticks(yTicks)
        .tickPadding(10)
        .tickFormat(d => {
          return `${d}%`;
        }),
      line: {
        expected: line<DataPoint>()
          .x(d => scales.x(d.date))
          .y(d => scales.y(d.expected))
          .curve(interpolation),
        zero: line<DataPoint>()
          .x(d => scales.x(d.date))
          .y(scales.y(0)),
      },
      area: {
        midRange: area<DataPoint>()
          .x(d => scales.x(d.date))
          .y0(d => scales.y(d.tenPercentile))
          .y1(d => scales.y(d.ninetyPercentile))
          .curve(interpolation),
      },
    };
  }

  createLayout() {
    this.svg = select<SVGGElement, DataPoint>('svg.ProjectionGraph-svg')
      .attr('width', this.settings.width + this.settings.margin.left + this.settings.margin.right)
      .attr('height', this.settings.height + this.settings.margin.top + this.settings.margin.bottom)
      .on('mousemove', () => {
        // @ts-ignore We need to confirm how this graph can be manually tested before changing this to this.svg.node()
        this.updateHover(this.settings.element);
      })
      .on('mouseout', () => {
        this.hideHover();
      })
      .append('g')
      .attr('transform', `translate(${this.settings.margin.left},${this.settings.margin.top})`);

    this.svg
      .append('g')
      .attr('class', 'ProjectionGraph-axis')
      .attr('transform', `translate(0,${this.settings.height})`)
      .call(this.generators.xAxis);

    this.svg
      .append('g')
      .attr('class', 'ProjectionGraph-axis--y ProjectionGraph-axis')
      .attr('transform', `translate(${this.settings.width} , 0)`)
      .call(this.generators.yAxis);

    this.svg.selectAll('g.tick').attr('class', 'ProjectionGraph-axisTick ProjectionGraph-label');
  }

  drawData() {
    this.drawArea('midRange');

    this.drawLine('expected');
    this.drawLine('zero');

    this.drawCircle('ninetyPercentile');
    this.drawCircle('tenPercentile');
    this.drawCircle('expected');
  }

  drawArea(type: string) {
    this.svg
      .append('path')
      .datum(this.settings.valuesByDate)
      .attr('class', `ProjectionGraph-area ProjectionGraph-area--${type}`)
      .attr('d', this.generators.area[type]);
  }

  drawLine(type: string) {
    this.svg
      .append('path')
      .datum(this.settings.valuesByDate)
      .attr('class', `ProjectionGraph-line ProjectionGraph-line--${type}`)
      .attr('d', this.generators.line[type]);
  }

  drawCircle(type: string) {
    this.svg
      .append('circle')
      .attr('r', 3)
      .attr('class', `ProjectionGraph-hover ProjectionGraph-circle ProjectionGraph-circle--${type}`)
      .attr('data-type', type);
  }

  drawHoverBar() {
    this.svg.append('path').attr('class', 'ProjectionGraph-hover ProjectionGraph-bar');
  }

  initHover() {
    const lastPoint = this.settings.valuesByDate[this.settings.valuesByDate.length - 1];
    this.updateHoverBar(lastPoint);
    this.updateTooltip({ point: lastPoint, mouseX: 0 });
    this.updateCircles(lastPoint);
    this.hideHover();
  }

  updateHoverBar(point: DataPoint) {
    const x = this.scales.x(new Date(point.date));
    select('.ProjectionGraph-hover.ProjectionGraph-bar')
      .classed('ProjectionGraph-hover--show', true)
      .attr('d', `M${x},${this.settings.height}L${x},${0}`);
  }

  updateTooltip(options: { point: DataPoint; mouseX: number }) {
    const tooltip: HTMLElement = document.querySelector(this.settings.tooltipClass);
    const { point } = options;
    const toolWidth = tooltip.offsetWidth;
    const offset = options.mouseX - toolWidth;
    const tooltipYPosition = this.settings.element.parentElement.offsetTop;

    tooltip.querySelector('.ProjectionGraph-tooltipDate').innerHTML =
      // @ts-ignore We need a way to manually test this before getting rid of the parseInteger to match the DataPoint interface
      `Year ${this.dateFormat(new Date(parseInteger(point.date)))} cumulative returns`;
    Array.from(tooltip.querySelectorAll('.ProjectionGraph-tooltipValue')).forEach(item => {
      const type = item.getAttribute('data-value-type');
      const f = format('+');
      item.innerHTML =
        // @ts-ignore We need a way to manually test this before getting rid of the parseInteger to match the DataPoint interface
        parseInteger(point[type]) === 0 ? `${parseInteger(point[type])}%` : `${f(parseInteger(point[type]))}%`;
    });

    function updateTooltipBoxPadding(scales: Scales) {
      const leftValue = scales.x(point.date) + 31 - (offset > 0 ? toolWidth - 2 : 1);
      tooltip.style.left = `${leftValue}px`;
      tooltip.style.top = `${tooltipYPosition}px`;
      tooltip.classList.add('ProjectionGraph-hover--show');
    }

    updateTooltipBoxPadding(this.scales);
  }

  // eslint-disable-next-line no-unused-vars
  updateCircles(point: DataPoint) {
    selectAll('.ProjectionGraph-circle')
      .classed('ProjectionGraph-hover--show', true)
      .attr('cx', this.scales.x(point.date));
    Array.from(document.querySelectorAll('.ProjectionGraph-circle')).forEach(circle => {
      const type = circle.getAttribute('data-type');
      circle.setAttribute('cy', `${this.scales.y(point[type])}`);
    });
  }

  updateHover(el: SVGGElement) {
    const container = el;
    const mouseX = mouse(container)[0] - this.settings.margin.left;
    const mouseY = mouse(container)[1];

    if (mouseX >= 0 && mouseX <= this.settings.width && mouseY >= 0 && mouseY <= this.settings.height) {
      const exactDate = this.scales.x.invert(mouseX);
      const point = this.getClosestDataPoint(exactDate);

      this.updateHoverBar(point);
      this.updateTooltip({
        point,
        mouseX,
      });
      this.updateCircles(point);
    } else {
      this.hideHover();
    }
  }

  getClosestDataPoint(date: Date) {
    const bisect = bisector<DataPoint, {}>(d => d.date);
    const index = Math.min(bisect.right(this.settings.valuesByDate, date), this.settings.valuesByDate.length - 1);

    return this.settings.valuesByDate[index];
  }

  hideHover() {
    selectAll('.ProjectionGraph-hover').classed('ProjectionGraph-hover--show', false);
  }

  dateFormat(date: Date) {
    return timeFormat('%Y')(date);
  }
}
