import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['maskedElement'];

  maskedElementTarget: HTMLElement;

  showUnmaskedDatum() {
    const maskedElement = this.maskedElementTarget;
    const { unmaskedDatum } = maskedElement.dataset;

    maskedElement.innerHTML = unmaskedDatum;
  }
}
