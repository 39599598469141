import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['rebalanceEnabledInput', 'rebalanceEnabledLabel', 'driftThresholdPercentInput', 'rebalanceInfo'];

  rebalanceEnabledInputTarget: HTMLInputElement;

  rebalanceEnabledLabelTarget: HTMLElement;

  driftThresholdPercentInputTarget: HTMLInputElement;

  rebalanceInfoTarget: HTMLElement;

  connect() {
    this.determineRebalanceEnabledLabel();
  }

  determineRebalanceEnabledLabel() {
    if (this.rebalanceEnabledInputTarget.checked) {
      this.rebalanceEnabledLabelTarget.innerText = 'On';
      this.driftThresholdPercentInputTarget.readOnly = false;
      this.driftThresholdPercentInputTarget.classList.remove('u-backgroundColor-bmtGrey10', 'u-color-bmtGrey50');
      this.rebalanceInfoTarget.classList.add('hidden');
    } else {
      this.rebalanceEnabledLabelTarget.innerText = 'Off';
      this.driftThresholdPercentInputTarget.readOnly = true;
      this.driftThresholdPercentInputTarget.classList.add('u-backgroundColor-bmtGrey10', 'u-color-bmtGrey50');
      this.rebalanceInfoTarget.classList.remove('hidden');
    }
  }
}
