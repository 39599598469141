import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['assetBasedRateInput', 'fixedRateInput', 'platformFeeDiv', 'totalRateDiv'];

  assetBasedRateInputTarget: HTMLInputElement;

  fixedRateInputTarget: HTMLInputElement;

  platformFeeDivTarget: HTMLDivElement;

  totalRateDivTarget: HTMLDivElement;

  isInteger(value: string) {
    return parseInt(value, 10) === parseFloat(value);
  }

  formatMoney(inputNumber: string) {
    const inputToNumber = parseFloat(inputNumber);
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(inputToNumber);
  }

  platformFeeAmount() {
    return parseInt(this.platformFeeDivTarget.dataset.platformfee, 10);
  }

  assetBasedTotalRateUpdatedAmount(userText: string) {
    if (this.isInteger(userText)) {
      const totalAmount = parseInt(userText, 10) + this.platformFeeAmount();

      return `${totalAmount} bps`;
    }
    return this.platformFeeDivTarget.innerText;
  }

  fixedTotalRateUpdatedAmount(userText: string) {
    const cleanedText = this.cleanInputString(userText);

    if (this.isInteger(cleanedText)) {
      return `${this.formatMoney(cleanedText)} + ${this.platformFeeDivTarget.innerText}`;
    }
    return this.platformFeeDivTarget.innerText;
  }

  updatedText(configurationType: string) {
    switch (configurationType) {
      case 'assetBased':
        return this.assetBasedTotalRateUpdatedAmount(this.assetBasedRateInputTarget.value);
      case 'fixed':
        return this.fixedTotalRateUpdatedAmount(this.fixedRateInputTarget.value);
      default:
        return '';
    }
  }

  overwriteTotalRate() {
    this.totalRateDivTarget.innerText = this.updatedText(this.totalRateDivTarget.dataset.configuration);
  }

  cleanInputString(userText: string) {
    return userText.replace(/,/g, '');
  }
}
