import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['loader', 'form'];

  loaderTarget: HTMLElement;

  formTarget: HTMLFormElement;

  stripeLoader() {
    this.loaderTarget.classList.remove('u-displayNone');
  }

  redirectStripePortal() {
    this.stripeLoader();
    document.body.classList.add('stripe-non-scrollable');
    setTimeout(() => {
      this.formTarget.submit();
    }, 1000);
  }
}
