import { Controller } from 'stimulus';

import ReturnsGraph, { type DataPoint, type ReturnsGraphOptions } from '../graphs/returns-graph';
import { parseInteger } from '../models/helpers';

class ControllerBase extends Controller {
  svgTarget: SVGElement;
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['svg'];

  resizeListener: () => void;

  connect() {
    this.draw();
    this.connectResizeListener();
  }

  disconnect() {
    this.disconnectResizeListener();
  }

  draw() {
    const chart = new ReturnsGraph(this.svgTarget, this.graphOptions);
    chart.draw();
  }

  connectResizeListener() {
    this.resizeListener = this.draw.bind(this) as () => void;
    window.addEventListener('resize', this.resizeListener);
  }

  disconnectResizeListener() {
    window.removeEventListener('resize', this.resizeListener);
  }

  get graphOptions(): ReturnsGraphOptions {
    return {
      minValue: parseInteger(this.data.get('min-value')),
      maxValue: parseInteger(this.data.get('max-value')),
      displayYAxis: Boolean(this.data.get('display-y-axis')),
      termYears: parseInteger(this.data.get('term-years')),
      valuesByDate: JSON.parse(this.data.get('values-by-date')) as DataPoint[],
    };
  }
}
