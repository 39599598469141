import { Controller } from 'stimulus';

const STRIPE_API_ERROR_RETRIES = 2;

export default class extends Controller {
  static targets = [
    'cardElement',
    'stripeErrorElement',
    'stripeErrorContainer',
    'cardForm',
    'form',
    'agreementCheckBox',
    'stripeSubmitButton',
  ];

  /* eslint-disable */
  cardElementTarget: HTMLElement;

  stripeErrorElementTarget: HTMLElement;

  stripeErrorContainerTarget: HTMLElement;

  cardFormTarget: HTMLFormElement;

  formTarget: HTMLFormElement;

  agreementCheckBoxTarget: HTMLInputElement;

  stripeSubmitButtonTarget: HTMLInputElement;

  stripe: any;

  elements: any;

  countdown: number;

  connect(): void {
    this.countdown = 0;

    try {
      this.stripe = window.Stripe(this.getPublishableKey());
      this.elements = this.stripe.elements({
        clientSecret: this.getClientSecret(),
      });
      const cardElement = this.elements.create('payment');
      cardElement.mount(`#${this.cardElementTarget.id}`);
    } catch (e) {
      this.hideAndUnblock();
    }
  }

  toggleTermsAcceptance() {
    this.stripeSubmitButtonTarget.disabled = !this.agreementCheckBoxTarget.checked;
  }

  async stripeSubmitForm() {
    if (this.countdown < STRIPE_API_ERROR_RETRIES) {
      this.lockButton();
      await this.connectedSubmit();
    } else {
      this.disconnectedSubmit();
    }
  }

  async connectedSubmit() {
    const errorMessage = this.stripeErrorElementTarget;
    const messageContainer = this.stripeErrorContainerTarget;
    try {
      const { setupIntent, error } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      });

      if (error) {
        if (error.type !== 'validation_error') {
          if (error.type !== 'card_error') {
            this.countdownPlus();
          }
          errorMessage.textContent = error.message;
        } else {
          errorMessage.textContent = 'There was an error saving your payment information. Please try again.';
        }
        messageContainer.classList.remove('u-displayNone');
        this.unlockButton();
      } else {
        this.statusIntent();
      }
    } catch (e) {
      this.unlockButton();
      this.countdownPlus();
    }
  }

  countdownPlus() {
    this.countdown++;

    if (this.countdown >= STRIPE_API_ERROR_RETRIES) {
      this.hideAndUnblock();
    }
  }

  disconnectedSubmit() {
    this.formTarget.submit();
  }

  lockButton() {
    this.stripeSubmitButtonTarget.textContent = 'Continuing...';
    this.stripeSubmitButtonTarget.disabled = true;
  }

  unlockButton() {
    this.stripeSubmitButtonTarget.textContent = 'Agree and continue';
    this.stripeSubmitButtonTarget.disabled = false || !this.agreementCheckBoxTarget.checked;
  }

  statusIntent() {
    this.stripe.retrieveSetupIntent(this.getClientSecret()).then(({ setupIntent }) => {
      const errorMessage = this.stripeErrorElementTarget;
      if (setupIntent.status === 'requires_payment_method') {
        errorMessage.innerText = 'Failed to process payment details. Please try another payment method.';
        this.unlockButton();
      } else {
        this.formTarget.submit();
      }
    });
  }

  private getClientSecret() {
    return this.cardFormTarget.dataset.secret;
  }

  private getPublishableKey() {
    return this.cardFormTarget.dataset.key;
  }

  private hideAndUnblock() {
    this.countdown = STRIPE_API_ERROR_RETRIES;
    document.querySelector('#payment-method-info').remove();
  }
}
