/// <reference types="vite/client" />
import { StimulusAppBootstrapper, type SentryAppConfig, type DataDogConfig, track } from '@betterment/js-runtime';
import { type Definition } from 'stimulus';
import { definitionsFromGlob } from 'stimulus-vite-helpers';
import * as Sentry from '@sentry/browser';
import TestTrack, { type AnalyticsProvider } from 'test_track_js_client';
import appConfig from './src/utils/appConfig';
import './src/index.css';

const controllers = import.meta.glob(['./src/**/*-controller.ts', './src/**/*-controller.tsx'], { eager: true });
const definitions: Definition[] = definitionsFromGlob(controllers);

const sentryConfig: SentryAppConfig = {
  sentryApiToken: appConfig.sentryApiToken || '',
  sentryEnvironment: appConfig.sentryEnvironment || '',
  sentryRelease: appConfig.sentryRelease || '',
  isReplayEnabled: false,
  sentryErrorReplaySampleRate: 1,
  user: appConfig.user!,
  sentryWhitelistUrls: ['betterment.com', 'app.bettermentadvisorsolutions.com', 'clients.betterment.com'],
};

const dataDogConfig: DataDogConfig = {
  applicationId: appConfig.dataDogApplicationId,
  clientToken: appConfig.dataDogClientToken,
  env: appConfig.dataDogEnv || 'development',
  site: 'datadoghq.com',
  service: 'institutional',
  replaySampleRate: 0,
  isRumEnabled: appConfig.dataDogEnabled,
  isLoggingEnabled: false,
  allowedTracingUrls: [/https:\/\/*\.bettermentforadvisors\.com/, /https:\/\/*\.betterment\.com/],
};

const testTrackAnalytics: AnalyticsProvider = {
  trackAssignment: function (_visitorId, assignment, callback) {
    track(
      'SplitAssigned',
      {
        splitName: assignment.getSplitName(),
        splitVariant: assignment.getVariant() ?? '',
        splitContext: assignment.getContext(),
      },
      () => callback(true)
    );
  },
  alias: () => {},
  identify: () => {},
};

export { appConfig };

export function startStimulusApplication() {
  TestTrack.initialize({
    analytics: testTrackAnalytics,
    errorLogger: message => Sentry.captureException(`test_track ${message}`),
  });
  const bootstrapper = new StimulusAppBootstrapper(definitions, sentryConfig, dataDogConfig);
  bootstrapper.initialize();
}
