import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    redirect: String,
    dismissUrl: String,
  };

  static targets = ['divElement', 'modal', 'redirectHolder'];

  divElementTargets: HTMLElement[];

  modalTarget: HTMLDivElement;

  redirectHolderTarget: HTMLElement;

  nextStep({ target }: { target: HTMLElement }): void {
    const step = parseInt(target.dataset.step, 10);

    if (target.dataset.isLastStep === 'true') {
      this.closeAndRedirect();
      return;
    }

    const currentStep = document.querySelector(`[data-page="${step}"]`);
    if (currentStep) {
      currentStep.classList.add('u-displayNone');

      const el = document.querySelector<HTMLElement>(`[data-page="${step + 1}"]`);

      if (el) {
        el.classList.remove('u-displayNone');
      }
    }
  }

  toggleStep(el: HTMLElement) {
    el.classList.toggle('u-displayNone');
  }

  closeAndRedirect() {
    const close = document.querySelector<HTMLElement>('.sc-Modal-close');
    close.click();
    window.location.href = this.redirectHolderTarget.dataset.redirectValue;
  }
}
