import { Controller } from 'stimulus';

import { copyToClipboard } from '../models/helpers';

class ControllerBase extends Controller {
  linkTarget: HTMLInputElement;

  successTextTarget: HTMLElement;
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['link', 'successText'];

  copy(event: Event) {
    event.preventDefault();
    copyToClipboard(this.linkTarget);
    this.successTextTarget.classList.remove('u-hidden');
  }
}
