import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['welcome', 'portfolios', 'assignment', 'completion'];

  welcomeTarget: HTMLDivElement;

  portfoliosTarget: HTMLDivElement;

  assignmentTarget: HTMLDivElement;

  completionTarget: HTMLDivElement;

  portfoliosStep() {
    this.toggleStep(this.welcomeTarget);
    this.toggleStep(this.portfoliosTarget);
  }

  assignmentStep() {
    this.toggleStep(this.portfoliosTarget);
    this.toggleStep(this.assignmentTarget);
  }

  completionStep() {
    this.toggleStep(this.assignmentTarget);
    this.toggleStep(this.completionTarget);
  }

  toggleStep(el: HTMLDivElement) {
    el.classList.toggle('u-displayNone');
  }
}
