import { Controller } from 'stimulus';

class ControllerBase extends Controller {
  notificationTarget: HTMLElement;

  dismissLinkTarget: HTMLElement;
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ['notification', 'dismissLink'];

  userDismissed(event: Event) {
    event.preventDefault();
    this.notificationTarget.classList.add('u-hidden');
    document.cookie = this.writeCookie;
  }

  private get writeCookie() {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    return `${this.toastCookieKey}=user_dismissed${expires}; path=/`;
  }

  private get toastCookieKey() {
    return this.notificationTarget.getAttribute('data-toast-cookie');
  }
}
