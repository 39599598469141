import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['agreementLink', 'fourOhOneInput', 'passwordLabel', 'passwordConfirmationLabel', 'passwordInput'];

  fourOhOneInputTarget: HTMLInputElement;

  passwordLabelTarget: HTMLInputElement;

  passwordConfirmationLabelTarget: HTMLInputElement;

  passwordInputTarget: HTMLInputElement;

  agreementLinkTarget: HTMLAnchorElement;

  connect(): void {
    this.determineAgreementLink();
    this.determinePasswordConfirmationState();
  }

  onFourOhOneToggle(): void {
    this.determineAgreementLink();
  }

  determineAgreementLink(): void {
    if (this.fourOhOneInputTarget.checked) {
      this.agreementLinkTarget.href = this.agreementLinkTarget.dataset.fourOhOneUrl;
    } else {
      this.agreementLinkTarget.href = this.agreementLinkTarget.dataset.advisorUrl;
    }
  }

  determinePasswordConfirmationState(): void {
    const passwordConfirmationErrorLabel = document.querySelector('#firm_password_confirmation_error');
    if (passwordConfirmationErrorLabel) {
      this.passwordInputTarget.classList.add('not-valid');
      this.passwordConfirmationLabelTarget.style.display = 'block';
      this.passwordLabelTarget.style.display = 'block';

      const passwordConfirmationLabel = document.createElement('label');
      passwordConfirmationLabel.setAttribute('for', 'firm_password');
      passwordConfirmationLabel.setAttribute('class', 'validation');
      passwordConfirmationLabel.innerHTML = passwordConfirmationErrorLabel.innerHTML;
      this.passwordLabelTarget.insertAdjacentElement('afterend', passwordConfirmationLabel);
    }
  }
}
