import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['taxId', 'taxClassification', 'taxClassificationLlc', 'taxClassificationOther'];

  taxIdTarget: HTMLInputElement;

  taxClassificationTargets: Array<HTMLInputElement>;

  taxClassificationLlcTarget: HTMLDivElement;

  taxClassificationOtherTarget: HTMLDivElement;

  connect() {
    const selected = this.taxClassificationTargets.find((button: HTMLInputElement) => button.checked);
    this.show(selected, this.taxClassificationLlcTarget, 'llc');
    this.show(selected, this.taxClassificationOtherTarget, 'other');
  }

  formatEin(event: KeyboardEvent) {
    if (!/[-]/.test(this.taxIdTarget.value) && event.code !== 'Minus') {
      this.taxIdTarget.value = this.taxIdTarget.value.replace(/^(.{2})/, '$1-');
    }
  }

  change() {
    const llcInput: HTMLSelectElement = this.taxClassificationLlcTarget.querySelector('select');
    const otherInput: HTMLInputElement = this.taxClassificationOtherTarget.querySelector('input');
    const selected = this.taxClassificationTargets.find((button: HTMLInputElement) => button.checked);

    this.taxClassificationLlcTarget.setAttribute('hidden', 'true');
    this.taxClassificationOtherTarget.setAttribute('hidden', 'true');

    llcInput.value = '';
    otherInput.value = '';

    this.show(selected, this.taxClassificationLlcTarget, 'llc');
    this.show(selected, this.taxClassificationOtherTarget, 'other');
  }

  show(selected: HTMLInputElement, wrapper: HTMLDivElement, val: string) {
    if (selected && selected.value === val) {
      wrapper.removeAttribute('hidden');
    }
  }
}
