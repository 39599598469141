import { Controller } from 'stimulus';

import RollupController from './rollup-table-controller';
import AdviceController from './self-directed-allocation-advice-controller';

import SelfDirectedPortfolio from '../models/self-directed-portfolio';

export default class extends Controller {
  static targets = ['rollupTable', 'submitButton', 'advice'];

  rollupTableTarget: HTMLElement;

  submitButtonTarget: HTMLButtonElement;

  adviceTarget: HTMLElement;

  connect() {
    this.connectInputListener();
  }

  disconnect() {
    this.disconnectInputListener();
  }

  connectInputListener() {
    this.rollupTableTarget.addEventListener('input', this.refresh.bind(this)); // eslint-disable-line @typescript-eslint/no-unsafe-argument
  }

  disconnectInputListener() {
    this.rollupTableTarget.removeEventListener('input', this.refresh.bind(this)); // eslint-disable-line @typescript-eslint/no-unsafe-argument
  }

  refresh() {
    this.toggleSubmitButton();
    this.toggleInvalidAdvice();
    this.triggerFormRefresh();
  }

  toggleSubmitButton() {
    if (this.portfolio.isValid()) {
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  triggerFormRefresh() {
    if (this.portfolio.isValid()) {
      const event = new Event('change', { bubbles: true });
      this.refreshableElement.dispatchEvent(event);
    }
  }

  toggleInvalidAdvice() {
    this.adviceController.setValid(this.portfolio.isValid());
    this.rollupTableController.setValid(this.portfolio.isValid());
  }

  get refreshableElement() {
    return this.element.querySelector('#refresh');
  }

  get rollupTableController() {
    return this.application.getControllerForElementAndIdentifier(
      this.rollupTableTarget,
      'rollup-table'
    ) as RollupController;
  }

  get adviceController() {
    return this.application.getControllerForElementAndIdentifier(
      this.adviceTarget,
      'self-directed-allocation-advice'
    ) as AdviceController;
  }

  get portfolio() {
    const components = this.rollupTableController.values;
    return new SelfDirectedPortfolio(components);
  }
}
