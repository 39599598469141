import $ from "jquery";

$(function () {
  $(document).on('change', '[data-behavior="file-input"] input', function (e) {
    var $hiddenInput = $(this),
        labelValue   = $hiddenInput.val().replace(/^.*\\/, "") || 'Select a file';

    $hiddenInput.siblings('.filename').text(labelValue);
  });
});
