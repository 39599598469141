import * as Sentry from '@sentry/browser';
import { Controller } from 'stimulus';

import { getCommonFetchConfig } from '../common-fetch-config';

export default class extends Controller {
  static targets = ['modal', 'taxComponent', 'outputComponent', 'cancelButton', 'continueButton'];

  modalTarget: HTMLDivElement;

  taxComponentTarget: HTMLDivElement;

  outputComponentTarget: HTMLElement;

  cancelButtonTarget: HTMLButtonElement;

  continueButtonTarget: HTMLButtonElement;

  rebalanceButton: HTMLElement | null;

  closeIcon: HTMLElement | null;

  connect(): void {
    const goalId = this.data.get('goalId');
    const isTaxableGoal = this.data.get('isTaxableGoal') === 'true';
    if (isTaxableGoal) {
      this.rebalanceButton = document.querySelector(
        `[data-sc-modal-target="[data-sc-modal-content='goal-${goalId}-rebalance-me-now']"`
      );
      this.closeIcon = this.modalTarget.querySelector('.sc-Modal-close');
      this.setListeners();
    } else {
      this.removeOutputComponent();
    }
  }

  setListeners() {
    this.addRebalanceButtonListener();
    this.addCloseModalListener();
    this.addContinueButtonListener();
  }

  addRebalanceButtonListener() {
    if (this.rebalanceButton) {
      this.rebalanceButton.addEventListener('click', () => {
        this.loadPartial();
      });
    }
  }

  addCloseModalListener() {
    if (this.closeIcon) {
      this.closeIcon.addEventListener('click', () => {
        this.refreshOutputComponent();
      });
    }
    this.cancelButtonTarget.addEventListener('click', () => {
      this.refreshOutputComponent();
    });
  }

  addContinueButtonListener() {
    this.continueButtonTarget.addEventListener('click', () => {
      this.disableCancelButton();
    });
  }

  refreshOutputComponent() {
    this.outputComponentTarget.innerText = 'Loading Tax Impact Preview...';
  }

  removeOutputComponent() {
    this.outputComponentTarget.style.display = 'none';
  }

  disableCancelButton() {
    this.cancelButtonTarget.disabled = true;
  }

  loadPartial() {
    fetch(`/goals/${this.data.get('goalId')}/tax_impact`, getCommonFetchConfig('get'))
      .then(response => response.text())
      .then(partial => {
        // nosemgrep: insecure-document-method, insecure-innerhtml
        this.outputComponentTarget.innerHTML = partial;
      })
      .catch(error => {
        Sentry.captureException(error);
        this.outputComponentTarget.innerText = "We can't complete this request. Please try again.";
      });
  }
}
